import React from 'react';
import { Button } from 'antd';
import { Control, useFormState, useWatch } from 'react-hook-form';

function SubmitButton({
  control,
  hasError,
}: {
  hasError?: boolean;
  control: Control<any>;
}) {
  const { isSubmitting } = useFormState({ control });

  const username = useWatch({
    control,
    name: 'username',
  });

  const disabled = !username?.trim();

  return (
    <Button
      block
      type="primary"
      htmlType="submit"
      disabled={disabled}
      loading={isSubmitting}
    >
      {!hasError ? 'Grab My Link' : 'Next'}
    </Button>
  );
}

export default SubmitButton;
