import React from 'react';
import { Typography } from 'antd';
import { Box } from '@shared/ui/box';
import { Title } from '@shared/ui/typography';

const { Text } = Typography;

function Header({ hasError }: { hasError?: boolean }) {
  return (
    <Box
      boxStyles={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {!hasError ? (
        <>
          <Title level={2}>Claim your unique link</Title>
          <Text type="secondary">It will be yours forever!</Text>
        </>
      ) : (
        <Title level={2}>Create your account</Title>
      )}
    </Box>
  );
}

export default Header;
