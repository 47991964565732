import React from 'react';
import { Form, Input } from 'antd';
import debounce from 'lodash.debounce';
import { Steps } from '@shared/ui/Utils';
import { Forms } from '@/lib/types/forms';
import { Title } from '@shared/ui/typography';
import { usernameRules } from '@shared/constants';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import Header from '@shared/ui/StoreUsername/components/Header';
import SubmitButton from '@shared/ui/StoreUsername/components/SubmitButton';
import UsernamePrefix from '@shared/ui/StoreUsername/components/UsernamePrefix';
import {
  Control,
  Controller,
  UseFormSetError,
  UseFormClearErrors,
} from 'react-hook-form';

function StoreUsername({
  submit,
  current,
  control,
  onSubmit,
  response,
  setError,
  hasError,
  clearErrors,
}: {
  submit: any;
  response: any;
  current: number;
  hasError?: boolean;
  onSubmit: () => any;
  control: Control<Forms.Register.StoreUsername>;
  setError: UseFormSetError<Forms.Register.StoreUsername>;
  clearErrors: UseFormClearErrors<Forms.Register.StoreUsername>;
}) {
  const is768 = useBreakpoint(768);
  const [success, setIsSuccess] = React.useState<boolean>(false);
  const [readOnly, toggleReadOnlyState] = React.useState<boolean>(true);

  React.useEffect(() => {
    toggleReadOnlyState(false);
  }, []);

  React.useEffect(() => {
    if (response?.error?.errors?.username) {
      setError('username', { message: response?.error?.errors?.username });
      setIsSuccess(false);
    } else if (response.isSuccess) {
      clearErrors();
      setIsSuccess(true);
    }
  }, [response, setError, clearErrors]);

  const debouncedOnChange = debounce((username: string) => {
    return submit({ username });
  }, 300);

  return (
    <>
      <Title level={!is768 ? 3 : 4}>
        Create Your Media Kit,
        <br />
        Get Brand Deals!
      </Title>
      <Steps current={current} />
      <Header hasError={hasError} />
      <form noValidate onSubmit={onSubmit}>
        <Form size="large" layout="vertical" component="div">
          <Controller
            name="username"
            control={control}
            rules={usernameRules}
            render={({ field, fieldState: { error } }) => (
              <Form.Item
                name="username"
                help={error?.message}
                hasFeedback={!!error?.message || success}
                validateStatus={
                  !!error?.message ? 'error' : success ? 'success' : undefined
                }
              >
                <Input
                  {...field}
                  type="text"
                  name="username"
                  autoComplete="off"
                  readOnly={readOnly}
                  placeholder="username"
                  prefix={<UsernamePrefix />}
                  onChange={(e) => {
                    field.onChange(e);

                    const val = e.target.value?.trim() || '';
                    if (val.length >= 5 && val.length <= 100) {
                      debouncedOnChange(e.target.value);
                    } else if (!val) {
                      clearErrors();
                      setIsSuccess(false);
                    }
                  }}
                />
              </Form.Item>
            )}
          />
          <Form.Item>
            <SubmitButton hasError={hasError} control={control} />
          </Form.Item>
        </Form>
      </form>
    </>
  );
}

export default StoreUsername;
