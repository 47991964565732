'use client';

import React from 'react';
import styled from '@emotion/styled';
import { breakpoints } from '@shared/theme';
import { Steps as AntdSteps, StepsProps } from 'antd';

const customDot: StepsProps['progressDot'] = (dot) => dot;

const StepsWrapper = styled('div')(() => ({
  width: '100%',
  display: 'grid',
  maxWidth: '100%',
  overflow: 'hidden',
  paddingTop: '.5rem',
  justifyContent: 'center',
}));

export const LogoWrapper = styled('a')(() => ({
  display: 'flex',
  [`@media (max-width: ${breakpoints.lg})`]: {
    '& img': {
      width: 130,
    },
  },
}));

export const Steps = ({ current }: { current?: number }) => {
  return (
    <StepsWrapper>
      <AntdSteps
        size="small"
        current={current}
        responsive={false}
        items={[{}, {}, {}]}
        progressDot={customDot}
      />
    </StepsWrapper>
  );
};
