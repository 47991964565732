import React from 'react';
import Image from 'next/image';
import { Typography } from 'antd';
import logoXs from '@shared/icons/logo-xs.svg';
import { Box } from '@shared/ui/box';

const { Text } = Typography;

function UsernamePrefix() {
  return (
    <Box
      boxStyles={{
        gap: '.5rem',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Image src={logoXs} alt="Viralmango logo" />
      <Text>viralmango.me /</Text>
    </Box>
  );
}

export default UsernamePrefix;
